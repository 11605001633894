/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/variables.scss";
@import "./assets/sass/style.angular.scss";
@import "./assets/sass/uppy.scss";
@import "./assets/sass/custom.scss";
@import "./assets/sass/config.scss";
@import "./assets/sass/flag.scss";
@import "./assets/sass/global/integration/plugins/bootstrap-datepicker";
@import "./assets/sass/global/integration/plugins/bootstrap-datetimepicker";
@import "./assets/sass/global/integration/plugins/bootstrap-daterangepicker";
@import "./assets/sass/global/integration/plugins/fontawesome5";
@import "./assets/sass/global/integration/plugins/datatables";
@import "./assets/sass/global/integration/plugins/sweetalert2";
@import "./assets/sass/global/integration/plugins/mixins/perfect-scrollbar";
@import "./assets/sass/calendar.scss";
@import "./assets/sass/global/components/general/head";
@import "./assets/sass/global/components/general/grid-nav-v2";
@import "./assets/sass/global/components/general/grid-nav";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "app/../node_modules/intl-tel-input/build/css/intlTelInput";

router-outlet ~ * {
  display: inline-block;
  padding: 35px 6px 20px 42px;
  width: 100%;
}

#phone {
  display: block !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  background-color: #ffffff !important;
  background-clip: padding-box !important;
  box-shadow: inset 0px 0px 11px 0px #eeeeee !important;
  width: 100% !important;
  height: 40px !important;
  line-height: 40px !important;
  border-radius: 10px !important;
  transition: all 0.3s ease-in-out !important;
  padding: 10px 10px !important;
  color: #0a0a0a !important;
  border: 1px solid #eeeeee !important;
  padding-left: 50px !important;
}
.intercom-lightweight-app-launcher {
  bottom: 62px !important;
}

@media (max-width: 1024px) {
  router-outlet ~ * {
    display: inline-block;
    padding: 22px 5px 5px 5px;
    width: 100%;
  }
}

.kt-header {
  left: 0px;
}

.email-text {
  margin-top: 15% !important;
  left: 0% !important;
}

body {
  background-color: #f5f9fc;
  font-family: "Lato", sans-serif;
}

.container {
  min-width: 1100px;
  width: 100%;
  max-width: 100%;
}

.iti{
  width: 100%;
}

.iti-flag {
  width: 20px;
}

.kt-header-menu-wrapper {
  z-index: 999999 !important;
  overflow-y: visible !important;
  backface-visibility: visible;
  -webkit-backface-visibility: visible;
}

.iti-flag-tag-input {
  padding-left: 52px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.search-button {
  box-sizing: border-box;
  position: relative;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: none;
  background-color: #22b9ff;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.doctor-template .kt-portlet__head-title {
  font-size: 1.8rem !important;
  color: #128ad6 !important;
}
.loading-overlay {
  width: 100vw;
  height: 100vh;
  background-color: #128ad6;
  z-index: 99999;
}

.mat-spinner circle {
  stroke: #fff;
}

.medical-record-button {
  background-color: $curious-blue-color;
  color: #fff;
  margin-left: 50px;
  padding: 0px 13px;
  border-radius: 0;
}

a.patient-name:hover {
  text-decoration: underline !important;
}

.cancel-btn {
  border-color: #e62e43 !important;
  color: #e62e43 !important;
  &:hover {
    background-color: #e62e43 !important;
    color: #fff !important;
  }
}

.confirm-btn {
  border-color: #5cb85c !important;
  color: #5cb85c !important;
  &:hover {
    background-color: #5cb85c !important;
    color: #fff !important;
  }
}

.showingup-btn {
  border-color: #f6852b !important;
  color: #f6852b !important;
  &:hover {
    background-color: #f6852b !important;
    color: #fff !important;
  }
}

.ordonnance-btn {
  border-color: #5cb85c !important;
  color: #5cb85c !important;
  &:hover {
    background-color: #5cb85c !important;
    color: #fff !important;
  }
}

.fc-content {
  position: relative;
}

.fc-content:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  opacity: 0.25;
}

.multi-clr-btns-box.multi-clr-btn-intable {
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li {
      .multi-clr-btn {
        padding: 5px;
        text-align: center;
        font-size: 12px;
        text-transform: capitalize;
        border: none;
        border-radius: 4px;
        width: auto;
        margin: 5px;
        flex-wrap: wrap;
      }
      .btn-red {
        background: red;
        color: #fff;
      }
      .btn-blue {
        background: blue;
        color: #fff;
      }
      .btn-green {
        background: green;
        color: #fff;
      }
      .btn-orange {
        background: orange;
        color: #fff;
      }
      .btn-purple {
        background: purple;
        color: #fff;
      }
      .btn-white-color {
        color: #fff;
      }
    }
  }
}

.intl-tel-input {
  .select-country {
    padding-left: 40px !important;
  }
  .flag-container {
    position: absolute;
    top: 10px !important;
    bottom: 0;
    right: 0;
  }
  .selected-flag {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    .iti-arrow {
      width: 0;
      height: 0;
      &.up {
        border-top: none;
      }
    }
  }
  .country-list {
    position: absolute;
    z-index: 2;
    .flag-box {
      display: inline-block;
    }
    padding: 0;
    white-space: nowrap;
    max-height: 200px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    .country {
      padding: 5px 10px;
    }
    .flag-box,
    .country-name,
    .dial-code {
      vertical-align: middle;
    }
    .flag-box,
    .country-name {
      margin-right: 6px;
    }
  }
  &.allow-dropdown,
  &.separate-dial-code {
    .flag-container {
      right: auto;
      left: 0;
    }
  }
  &.iti-container {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: 1060;
    &:hover {
      cursor: pointer;
    }
  }
}

@media (max-width: 1024px) {
  .container {
    min-width: 900px !important;
    width: 100%;
    overflow: hidden;
  }
  .kt-header-mobile {
    top: 0px;
    &__logo {
      img {
        width: 140px;
      }
    }
  }
}

@media (max-width: 1125px) {
  .container {
    min-width: 900px !important;
    width: 100%;
    padding-left: 2% !important;
    margin-right: 5% !important;
  }
  .medical-record-search {
    margin-top: -8px;
  }
  .medical-record-button {
    margin-left: 20px;
  }
  .buttons-wrapper {
    margin-left: -18px !important;
    padding: 5px;
  }
  .absense-btn,
  .consultation-btn {
    width: 130px;
    font-size: 12px;
  }
}

@media (max-width: 892px) {
  .container {
    min-width: 100% !important;
    width: 100%;
  }
  .medical-record-search {
    margin-top: -18px;
    margin-left: 20px;
  }
  .medical-record-button {
    margin-left: 16px;
    font-size: 12px;
    padding: 0px 8px !important;
  }
  .doctor-stats-card {
    width: 100%;
    .mat-card-content {
      padding: 0 10%;
    }
  }
  .print-button {
    float: right;
  }
  .mat-table {
    font-size: 14px;
  }
}

@media (max-width: 703px) {
  .container {
    min-width: 600px !important;
    width: 100%;
  }
  .kt-searchbar .kt-input-icon .form-control {
    width: 250px;
  }
  .medical-record-search {
    width: 250px;
  }
}

@media (max-width: 634px) {
  .medical-record-search {
    margin-top: 8%;
  }
}

@media (max-width: 593px) {
  .container {
    min-width: 500px !important;
    width: 100%;
  }
}

@media (max-width: 526px) {
  .container {
    min-width: 400px !important;
    width: 100%;
  }
  .mat-chip-list-wrapper .mat-standard-chip {
    margin: 0 !important;
  }
}

@media (max-width: 414px) {
  .container {
    min-width: 300px !important;
    width: 100%;
  }
  .mat-chip-list-wrapper {
    .mat-standard-chip {
      width: 115px !important;
      font-size: 11px !important;
    }
  }
  .table {
    .mat-header-cell {
      padding: 0 10px;
    }
  }
}
table.mat-table {
  width: 100%;
}
table .mat-header-cell {
  font-weight: 700 !important;
  font-size: 16px;
}
.patient-table th {
  border: 1px solid #ddd;
}
table.mat-table th {
  padding-left: 10px;
}

app-root.no-scroll {
  overflow: hidden;
}

.swal2-container
{
  height: 106%;
}