.ml-auto {
  margin-left: auto;
}

.example-full-width {
  width: 100%;
}

.form-group .kt-timeline-3 {
  &::before {
    background-color: transparent;
  }
}

// kt-form
.kt-form {
  .kt-form__filtration,
  .kt-form__group-action {
    margin: 0 0 30px;
  }
  .kt-form__actions.kt-form__actions--sm {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .kt-form__actions {
    padding: 30px;
    &.kt-form__actions--solid {
      padding: 30px;
      background-color: #f7f8fa;
      border-color: #f7f8fa;
    }
  }
}

.kt-margin {
  &-top-20 {
    margin-top: 20px;
  }
  &-bottom-20 {
    margin-bottom: 20px;
  }
}

.kt-padding-0 {
  padding: 0;
}

.kt-form.kt-form--group-seperator .kt-form__group,
.kt-form.kt-form--group-seperator-dashed .kt-form__group {
  padding-top: 25px;
  padding-bottom: 25px;
}
