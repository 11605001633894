@import "variables";
@import "shared_table";

.btn-outline-neutral {
  color: $curious-blue-color;
  border-color: $curious-blue-color;
  &:hover {
    background-color: $curious-blue-color;
    border-color: $curious-blue-color;
    color: $white-color;
    i {
      color: $white-color;
    }
  }
}

.btn-outline-cancel {
  color: $storm-grey;
  border-color: $storm-grey;
  &:hover {
    background-color: $storm-grey;
    border-color: $storm-grey;
    color: $white-color;
    i {
      color: $white-color;
    }
  }
}

.save-btn {
  background-color: $crusta;
  border-color: $crusta;
  color: $white-color;
  &:hover {
    background-color: $crusta;
    border-color: $crusta;
    color: $white-color;
    i {
      color: $white-color;
    }
  }
}

.add-btn {
  background-color: $curious-blue-color;
  border-color: $curious-blue-color;
  color: $white-color;
  &:hover {
    background-color: $curious-blue-color;
    border-color: $curious-blue-color;
    color: $white-color;
    i {
      color: $white-color;
    }
  }
}

.create-btn {
  background-color: $curious-blue-color;
  color: $white-color;
  margin-left: 50px;
  padding: 0px 13px;
  border-radius: 0;
}

.confirm-new-btn {
  background-color: $soft-green;
  border-color: $soft-green;
  color: white;
  margin: 0 1px;
  i {
    color: $white-color !important;
  }
}

.delete-btn {
  background-color: $persian-rose;
  border-color: $persian-rose;
  color: white;
  margin: 0 1px;
  i {
    color: $white-color !important;
  }
}

.add-prescription-btn {
  background-color: $robin-egg-blue;
  border-color: $robin-egg-blue;
  color: white;
  margin: 0 1px;
  i {
    color: $white-color !important;
  }
}

.flag-btn {
  background-color: $crusta;
  border-color: $crusta;
  color: white;
  margin: 0 1px;
  i {
    color: $white-color !important;
  }
}
.grey-flag-btn {
  background-color: #fff;
  border-color: $storm-grey;
  color: white;
  margin: 0 1px;
  i {
    color: $storm-grey !important;
  }
}
.centralized-btns {
  justify-content: center;
}

.delete2-btn {
  background-color: $robin-egg-blue;
  border-color: $robin-egg-blue;
  color: white;
  margin: 0 1px;
  i {
    color: $white-color !important;
  }
}

.block-btn {
  background-color: $persian-rose;
  border-color: $persian-rose;
  color: $white-color;
  &:hover {
    background-color: $persian-rose;
    border-color: $persian-rose;
    color: $white-color;
    i {
      color: $white-color;
    }
  }
}

.edit-btn {
  background-color: $pink-swan;
  border-color: $pink-swan;
  color: white;
  margin: 0 1px;
  i {
    color: $white-color !important;
  }
}

.domicile-primary {
  background: $pacific_blue;
  color: $white-color;
}


.tooltip-arrow::before
{
  border-top-color: black !important;
}
.tooltip-inner
{
  background-color: black !important;
  color: white !important;
}