$curious-blue-color: #2688c9;
$storm-grey: #808285;
$crusta: #f6852b;
$soft-green: #5bb75b;
$persian-rose: #e62e43;
$robin-egg-blue: #10a693;
$white-color: #fff;
$harley-davidson-orange: #db3d00;
$thunderbird: #be0f0f;
$solitude: #e2e5ec;
$pink-swan: #b3b3b3;
$light-green: #3dbeb1;
$white: #fff;
$lime: #79a70a;
$pacific_blue: #128ad6;
$deep_sky_blue: #22b9ff;
$red: #ff0000;
$grey: #7f7f7f;
$catskill-White: #eaf0f4;
$smoky: #59595c;
$silver_shade: #c4bfbf;
$silver: #c5c5c5;
$dark-charcoal: #333;
$dove-gray: #666;
$gray: #ddd;
$dim-gray: #727272;
$lynx-white: #f7f7f7;
$silver: #c5c5c5;
$dark-charcoal: #333;
$gainsboro: #d9d9d9;
$geyser: #ccd6dd;
$cool-grey: #8899a6;
$chetwode-blue-color: #6e76ce;
$waikawa-grey: #646c9a;
$black: #000;
$dark-gray: #626462;
$amber: #ffc107;
$amaranth: #dc3545;
$fern: #5cb85c;
$pale-sky: #6c747e;
$vermillion: #da4f49;
$very-soft-cyan: #bce8f1;
$Gray20: #333333;
$chinese_silver: #ccc;
$pantone: #49c5b1;
$light-shade-of-gray: #eee;
$moderate-lime-green: #4cae4c;
$philippine-gray: #8d8c94;
$baby-blue-eyes: #a5d2ff;
$bright-gray: #eaeaea;
$razzle-dazzle-rose: #fd27eb;
$porcelain: #eceff1;
$iron: #dee1e3;
$black-squeeze: #f5f9fc;
$indigo: #4881cd;
$java: #1dc9b7;
