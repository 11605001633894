.appointment-calendar {
  margin-top: 20px;
}
@media (max-width: 768px) {
  .modal-backdrop {
    display: none;
  }
  #edit_booking .modal-dialog,
  #new_booking .modal-dialog {
    width: 95%;
    margin-top: 15vh;
  }
  .login::before {
    background-color: #ffffff !important;
  }
  .appointment-calendar {
    margin-top: 0px;
  }
  .drappointment {
    display: none;
  }
}
.sidebarnav {
  background: none;
  list-style: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
}
.bookingtimerow {
  padding-right: 45px !important;
  position: relative;
  min-height: 30px;
  width: 100%;
  .booking_data {
    text-align: left;
  }
}
.bookingslot {
  cursor: pointer;
}

.confirm_booking,
.cancel_booking {
  position: absolute;
  right: 15px;
  width: 30px;
  height: 30px;
  padding: 6px;
  background-color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 20px !important;
}

.confirm_booking {
  top: 10px;
  color: #5cb85c;
}

.cancel_booking {
  top: 45px;
  color: rgb(171, 51, 30);
}

.confirmation_caption,
.cancelation_caption {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 2%;
  color: #fff !important;
  display: none;
  text-align: center;
  z-index: 2;
}

.confirmation_caption {
  background: rgba(66, 200, 2, 0.75);
}

.cancelation_caption {
  background: rgba(255, 0, 0, 0.75);
}

.sidebarnav li a {
  font-size: 14px;
  line-height: 18px;
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 15px 15px 15px 45px;
}
.sidebarnav li a i:before {
  padding: 3px;
  display: inline-block;
  border-radius: 2px;
  margin-right: 5px;
  font-size: 16px;
  text-align: center;
  width: 25px;
  line-height: 22px;
  height: 25px;
  position: absolute;
  left: 15px;
  top: 12px;
}
.sidebarnav li a:hover,
.sidebarnav li a:focus {
  background-color: #6fa7f0;
}
.sidebarpanel.panel-primary {
  padding: 0px;
  border: 0px;
  background: white;
  border-color: #337ab7;
}
.sidebarpanel .panel-body {
  padding: 0px;
  border: 0px;
}
.sidebarpanel.panel-primary > .panel-heading {
  background-color: #6199e4;
  border-color: #6199e4;
  text-align: left;
}
.sidebarpanel.panel-primary > .panel-heading a.fa {
  float: right;
  font-size: 25px;
  line-height: 23px;
  color: #fff;
}
.sidebarpanel.panel-primary > .panel-heading a:hover,
.sidebarpanel.panel-primary > .panel-heading a:focus {
  color: #fff;
  text-decoration: none;
}

body.calendar-page .footer2 {
  display: none !important;
}
body.calendar-page .content2 {
  padding-bottom: 0px;
}
@media (max-width: 767px) {
  .sidebarnav {
    text-align: center;
  }
  .sidebarnav li {
    margin: 0px;
    display: inline-block;
    border: 0px;
  }
  .sidebarnav li a,
  .sidebarnav li a:hover,
  .sidebarnav li a:focus {
    // padding: 5px 5px !important;
    overflow: hidden;
    width: 40px;
    height: 40px;
    font-size: 10px;
    line-height: 10px;
    background: none;
    .badge {
      display: none;
    }
  }
  .sidebarnav li a i:before {
    padding: 10px;
    background: none;
    margin-right: 0px;
    color: #4881cd;
    border-radius: 3px;
    font-size: 22px;
    width: 40px;
    line-height: 22px;
    height: 40px;
    left: 0px;
    top: 0px;
  }
  .sidebarpanel.firstchild {
    margin-bottom: 0px;
  }
}

.NoRecordMsg {
  display: inline-block;
  width: 100%;
  font-weight: normal;
}
@media (max-width: 768px) {
  .drprofileinfo .popover {
    left: 50% !important;
    top: 150px !important;
    min-width: 300px !important;
    margin-left: -150px !important;
  }
  .drprofileinfo .popover > .arrow {
    display: none !important;
  }
}

.bookingslottime {
  margin-bottom: 15px;
  margin-top: 5px;
}

.booking-info {
  font-weight: 400;
}

.booking-name {
  margin-right: 5px;
}

.cancel_day {
  vertical-align: middle !important;
}
.cancel-day-btn {
  cursor: pointer;
  display: block !important;
  font-size: 2em !important;
  text-align: center;
}

.cancel-day-btn:hover {
  color: #da4f49;
}

.canceled_day {
  background: repeating-linear-gradient(
    45deg,
    #6199e4,
    #6199e4 -2px,
    #88b2ea 10px,
    #a1afe8 16px
  );
}

.modal-dialog {
  width: 400px;
}

.category-input {
  height: 30px;
}

.calendar {
  .twitter-typeahead {
    width: 100%;
  }

  .typeahead *,
  .typeahead *:before,
  .typeahead *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  /* typeahead */

  .tt-menu {
    position: absolute;
    top: 95%;
    left: 2.5%;
    z-index: 100;
    display: none;
    max-height: 400px;
    overflow-y: auto !important;

    width: 99.7%;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #fff;
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
    box-shadow: 0px 0px 0px 1px green;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  .tt-menu.is-open {
    display: block;
  }

  .typeahead-selectable {
    cursor: pointer;
  }

  .typeahead-selectable + .typeahead-selectable {
    border-top: 1px solid #ccd6dd;
  }

  /* scaffolding */
  /* ----------- */

  .tt-dropdown-menu,
  .gist {
    text-align: left;
  }

  /* site theme */
  /* ---------- */

  .typeahead,
  .tt-query,
  .tt-hint {
    height: 30px;
    padding: 8px 12px;
    font-size: 24px;
    line-height: 30px;
    border: 2px solid #ccc;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    outline: none;
  }

  .typeahead {
    background-color: #fff;
  }

  .typeahead:focus {
    border: 2px solid #0097cf;
  }

  .tt-query {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }

  .tt-hint {
    color: #999;
  }

  .tt-menu {
    width: 370px;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  .tt-suggestion {
    padding: 1px 7px;
    line-height: 24px;
  }

  .tt-suggestion:hover {
    cursor: pointer;
    color: #fff;
    background-color: #0097cf;
  }

  .tt-suggestion.tt-cursor {
    color: #fff;
    background-color: #0097cf;
  }

  .tt-suggestion p {
    margin: 0;
  }
}

.firstchild.menu {
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  #calendar {
    margin: 0;
    padding: 0;
  }
  #edit_booking,
  #new_booking {
    .modal-dialog {
      width: 95%;
    }
  }
  #cancel_rdv,
  #edit_rdv {
    width: 48%;
  }
  #delete_rdv {
    margin-top: 10px;
    width: 99%;
  }
}

#calendar {
  .fc-right,
  .fc-left {
    margin-bottom: 5px;
  }
}

.more-types,
.less-types {
  padding: 10px;
  color: #6199e4;
  text-align: center;
  cursor: pointer;
}

.more-types:hover,
.less-types:hover {
  text-decoration: underline;
}

.loading-calendar {
  position: absolute;
  left: 48%;
  top: 35%;
  z-index: 5;
  color: #222;
}

#kt_portlet{
  position: sticky !important;
  top: 85px;
}

.w-full{
  width: 100%;
}

.swal2-container.swal2-shown {
  height: 116%;
}