.table-cell-block .mat-header-cell {
    display: inline-block;
    padding: 0px;
    border: 1px solid #ddd;
    height: 170px;
  }
  
  .table-cell-block .table-heading {
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 10px;
    color: #626462;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600
  }

  .mat-block.mat-cell:after {
        content: "";
        width: 1px;
        height: 100%;
        background: #ddd;
        position: absolute;
        top: 0;
        right: 0;
  }
  
  .mat-group-row {
    background: #b9d3e6;
  }
  
  .mat-group-cell {
    padding: 0 10px !important;
  }
  
  .mat-cell, .mat-footer-cell:first-child {
    border-left: 1px solid #ddd;
  }
  
  .content-wrapper .mat-cell:first-of-type, .content-wrapper mat-footer-cell:first-of-type, .content-wrapper mat-header-cell:first-of-type {
    padding-left: 0;
  }
  
  .mat-block.mat-cell {
    position: relative;
  }
  
  .kt-badge.kt-badge--pill {
    border-radius: 2rem;
    margin-left: 10px;
  }
  
  .kt-badge i
  {
    margin-left: 5px;
  }
  
  .mr-10 {
    margin-left: 10px;
  }
  
  mat-cell:last-of-type, mat-footer-cell:last-of-type, mat-header-cell:last-of-type {
    padding-right: 0px !important;
  }
  
  mat-cell, mat-header-cell, mat-footer-cell {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    word-wrap: break-word;
    min-height: inherit;
  }
  
  mat-row, mat-header-row, mat-footer-row {
    display: flex;
    border-width: 0;
    border-bottom-width: 1px;
    border-style: solid;
    align-items: center;
    box-sizing: border-box;
  }