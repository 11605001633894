//
// Topbar
//

$kt-header-topbar-item-size: 40px;
$kt-header-topbar-lage-flag-size: 19px;
$kt-header-topbar-user-avatar-size: 34px;
$kt-header-topbar-item-size-mobile: 34px;
$kt-header-topbar-user-avatar-size-mobile: 30px;

.kt-header__topbar {
  display: flex;
  align-items: stretch;
  padding: 0;
  padding-right: kt-get($kt-page-padding, desktop);

  .kt-header__topbar-item {
    display: flex;
    align-items: stretch;
    margin: 0 0.1rem;

    .kt-header__topbar-wrapper {
      cursor: pointer;
      display: flex;
      align-items: stretch;

      .kt-badge.kt-badge--notify {
        position: absolute;
        left: 50%;
        margin-left: -2px;
        top: 14px;
      }
    }

    .kt-header__topbar-icon {
      display: flex;
      align-items: center;
      align-self: center;
      justify-content: center;
      height: $kt-header-topbar-item-size;
      width: $kt-header-topbar-item-size;
      cursor: pointer;
      border-radius: 50%;
      @include kt-transition();

      i {
        @include kt-transition();
        font-size: 1.4rem;
        color: #b8bac3;
      }
    }

    // Languages
    &.kt-header__topbar-item--langs {
      .kt-header__topbar-icon {
        img {
          border-radius: 50%;
          width: $kt-header-topbar-lage-flag-size;
        }
      }

      .dropdown-menu {
        .kt-nav__link-icon {
          padding-right: 10px;

          img {
            border-radius: 50%;
            width: 18px;
          }
        }
      }
    }

    &:hover,
    &.show {
      .kt-header__topbar-icon {
        @include kt-transition();
        background-color: $kt-brand-hover-color;

        i {
          @include kt-transition();
          color: kt-brand-color();
        }
      }
    }

    // User profile
    &.kt-header__topbar-item--user {
      margin-left: 0.5rem;

      .kt-header__topbar-wrapper {
        .kt-header__topbar-welcome {
          display: flex;
          align-self: center;
          padding: 0 0.55rem 0 0;
          font-weight: 500;
          font-size: 0.9rem;
          color: #636177;
        }

        .kt-header__topbar-username {
          display: flex;
          align-self: center;
          padding: 0 0.55rem 0 0;
          font-weight: 500;
          font-size: 1rem;
          color: #fff;
        }

        img {
          align-self: center;
          max-height: $kt-header-topbar-user-avatar-size;
          border-radius: 50%;
        }

        .kt-header__topbar-icon {
          background-color: kt-brand-color();
          font-size: 1.5rem;
          font-weight: 500;
          color: #fff;

          > i {
            color: #fff;
          }
        }
      }

      &:hover,
      &.show {
        .kt-header__topbar-icon {
          background-color: kt-brand-color();
          color: #fff;

          > i {
            color: #fff;
          }
        }
      }
    }

    // Quickpanel
    &.kt-header__topbar-item--quickpanel {
      margin-left: 0.5rem;

      .kt-header__topbar-icon {
        background-color: $kt-secondary-brand-color;

        > i {
          color: #fff;
        }
      }

      &:hover,
      &.show {
        .kt-header__topbar-icon {
          background-color: $kt-secondary-brand-color;

          > i {
            color: #fff;
          }
        }
      }
    }
  }
}

@include kt-tablet-and-mobile {
  .kt-header__topbar {
    padding: 0 kt-get($kt-page-padding, mobile);
    background-color: #fff;
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-top: -(kt-get($kt-header-config, topbar, height, mobile));
    height: kt-get($kt-header-config, topbar, height, mobile);
    position: absolute;
    left: 0;
    right: 0;
    justify-content: flex-end;

    // Fixed mobile header
    .kt-header-mobile--fixed & {
      position: fixed;
      z-index: kt-get($kt-header-config, base, mobile, self, fixed, zindex) - 1;
    }

    // Topbar shown
    .kt-header__topbar--mobile-on & {
      margin-top: 0;
      transition: all 0.3s ease;
    }

    // Topbar item
    .kt-header__topbar-item {
      margin: 0 2px;
      padding: 0;

      .kt-header__topbar-wrapper {
        display: flex;
        align-items: stretch;

        .kt-badge.kt-badge--notify {
          top: 6px;
        }
      }

      .kt-header__topbar-icon {
        height: $kt-header-topbar-item-size-mobile;
        width: $kt-header-topbar-item-size-mobile;

        i {
          font-size: 1.3rem;
        }
      }

      &.kt-header__topbar-item--user {
        margin-left: 2px;
        margin-right: 0;
        padding: 0;

        .kt-header__topbar-wrapper {
          padding: 0 2px;

          .kt-header__topbar-welcome {
            padding: 0 0.35rem 0 0;
            font-size: 0.9rem;
          }

          .kt-header__topbar-username {
            padding: 0 0.55rem 0 0;
            font-size: 0.9rem;
          }

          img {
            max-height: $kt-header-topbar-user-avatar-size-mobile;
          }

          .kt-badge.kt-badge--username {
            height: $kt-header-topbar-user-avatar-size-mobile;
            width: $kt-header-topbar-user-avatar-size-mobile;
          }
        }
      }
    }
  }
}
