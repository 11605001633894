//
// Header
//

// Styles
$kt-header-item-icon-color: rgba(kt-brand-color(), 0.7);
$kt-header-item-hover-bg: rgba(#f0f3ff, 0.7);

// Desktop mode
@include kt-desktop {
  // Header
  .kt-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    height: kt-get($kt-header-config, base, desktop, default, height);
    transition: kt-get($kt-aside-config, base, minimize, transition);
    background-color: #fff;
    box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);

    // Fixed
    &.kt-header--fixed {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: kt-get($kt-header-config, base, desktop, fixed, zindex);
    }

    // Fixed header & fixed subheader
    .kt-header--fixed.kt-subheader--fixed & {
      border-bottom: 1px solid #eff0f6;
      box-shadow: none;
    }

    // Title
    .kt-header__title {
      display: flex;
      align-self: center;
      font-weight: 500;
      font-size: 1.4rem;
      color: #434349;
      margin-left: kt-get($kt-page-padding, desktop);
      margin-right: 1rem;
    }
  }
}

// Mobile mode
@include kt-tablet-and-mobile {
  // Header
  .kt-header {
    // Title
    .kt-header__title {
      display: none;
    }

    // Topbar
    .kt-header__topbar {
      background-color: #ffffff;
      box-shadow: none;

      // Topbar expaneded on mobile mode
      .kt-header__topbar--mobile-on & {
        box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
        border-top: 1px solid kt-base-color(grey, 2);
      }
    }
  }
}
