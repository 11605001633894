//
// Header Menu
//

// Desktop Mode
@include kt-menu-hor-build-layout(kt-get($kt-header-config, menu, desktop));
@include kt-menu-hor-build-skin(
  kt-get($kt-header-config, menu, desktop),
  default
);

// Tablet & Mobile Mode
@include kt-menu-ver-build-layout(
  kt-get($kt-header-config, menu, tablet-and-mobile)
);
@include kt-menu-ver-build-skin(
  kt-get($kt-header-config, menu, tablet-and-mobile),
  default
);

// Header Menu Mobile Offcanvas
@include kt-offcanvas-build(
  kt-header-menu-wrapper,
  tablet-and-mobile,
  kt-get($kt-header-config, menu, offcanvas-mobile)
);

// Desktop Mode
@include kt-desktop {
  // Header menu
  .kt-header-menu {
    // Menu nav
    .kt-menu__nav {
      // Menu item
      > .kt-menu__item {
        &:first-child {
          padding-left: 0;
        }

        // Menu link
        > .kt-menu__link {
          padding: 0.75rem 1.25rem;

          @include kt-rounded {
            border-radius: 40px;
          }

          > .kt-menu__link-text {
            color: #8a88a2;
            font-weight: 500;
          }

          > .kt-menu__link-icon {
            color: #8a88a2;
          }

          > .kt-menu__hor-arrow {
            color: #8a88a2;
          }
        }

        // Menu submenu
        > .kt-menu__submenu {
          > .kt-menu__subnav {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }

        // Menu item states
        &.kt-menu__item--active,
        &.kt-menu__item--here {
          > .kt-menu__link {
            background-color: $kt-brand-hover-color;
          }
        }

        &:hover,
        &.kt-menu__item--active,
        &.kt-menu__item--here,
        &.kt-menu__item--hover {
          > .kt-menu__link {
            > .kt-menu__link-text {
              color: kt-brand-color();
            }

            > .kt-menu__link-icon {
              color: kt-brand-color();
            }

            > .kt-menu__hor-arrow {
              color: kt-brand-color();
            }
          }
        }
      }
    }
  }
}
